exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-project-tags-tsx": () => import("./../../../src/templates/project-tags.tsx" /* webpackChunkName: "component---src-templates-project-tags-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

